
import { Component, Vue } from 'vue-property-decorator';
import { MemberService, RoundUpService } from '@/services';

// Components
import PageNotFound from '@/views/PageNotFound.vue';
import { FeatureModule, RoundUpModule } from '@/store/modules';
import { RoundUpAbout, RoundUpToolTip, RoundUpMinMaxAmountTooltip } from '@/components/round-up';
import { AddRoundUpProgram } from './';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';
import { IBasePaged } from '@/types/base.types';
import { CardDetail } from '@/types/user.types';
import { RoundUpRuleStatus as RoundUpRuleStatusEnum } from '@/types/round-up.types';

@Component({
  components: { AddRoundUpProgram, PageNotFound, RoundUpAbout, RoundUpToolTip, RoundUpMinMaxAmountTooltip }
})
export default class RoundUpProgram extends Vue {
  /******************* DATA *******************/
  changePausedRoundUp = false;
  currency: any;
  currentBalanceDeal: null | RoundUpRuleStatusEnum = null;
  featureStatuses = FeatureModule.getFeatureStatuses;
  ecosystemMinimum = RoundUpModule.getEcosystemMinimum ?? 0;
  blueTextColor = tenants[ActiveTenant].blueTextColor;
  enrollRoundUpPlatformFeeText = tenants[ActiveTenant].roundUps.enrollRoundUpPlatformFeeText;

  // Utilities
  snackbar = {
    show: false,
    error: '',
    success: ''
  };
  loading = false;
  // We can't access the import directly inside of template
  roundUpRuleStatus = RoundUpRuleStatusEnum;
  termsLink = tenants[ActiveTenant].links.termsConditions;
  privacyLink = tenants[ActiveTenant].links.privacyPolicy;
  cards: IBasePaged<CardDetail> = {
    totalNumberOfPages: 0,
    totalNumberOfRecords: 0,
    items: []
  };

  // Round up rule
  ecosystemFee = RoundUpModule.getEcosystemFee ?? 0;
  isEnrolledInFee = RoundUpModule.getRoundUp?.isFeeEnabled ?? false;
  isPaused = RoundUpModule.getRoundUp?.paused ?? false;
  maximumRoundUp = RoundUpModule.getRoundUp?.maximumRoundUps ?? null;
  minimumRoundUp = RoundUpModule.getRoundUp?.minimumRoundUps ?? RoundUpModule.getEcosystemMinimum;
  roundUpRuleId = RoundUpModule.getRoundUp?.id ?? null;

  roundUpStats = RoundUpModule.getRoundUpStats;

  /******************* METHODS *******************/
  clearMessages(val: boolean) {
    if (!val) {
      this.snackbar.success = '';
      this.snackbar.error = '';
    }
  }
  resetData(): void {
    this.changePausedRoundUp = false;
    this.currentBalanceDeal = null;
  }

  getDefaultLast4Digits(cardsPaginated: IBasePaged<CardDetail>): string | undefined {
    if (!cardsPaginated || !Array.isArray(cardsPaginated.items)) {
      return undefined;
    }
    const defaultCard = cardsPaginated.items.find((item: CardDetail) => item.isDefault);
    return defaultCard?.last4Digits;
  }

  async getRoundUpGlobalSettings(): Promise<void> {
    try {
      await RoundUpService.getRoundUpGlobalSettings();
      this.ecosystemFee = RoundUpModule.getEcosystemFee ?? 0;
      if (!this.minimumRoundUp) this.minimumRoundUp = RoundUpModule.getEcosystemMinimum;
    } catch (e: any) {
      this.$notifyError('Something went wrong');
    }
  }

  async fetchLinkedCards(): Promise<void> {
    try {
      this.cards = await MemberService.getCards();
    } catch (error) {
      console.log(error);
    }
  }

  async fetchRoundUpStats(): Promise<void> {
    await RoundUpService.getRoundUpStats();
    this.roundUpStats = RoundUpModule.getRoundUpStats;
  }

  async fetchRoundUpRuleForUser(): Promise<void> {
    await RoundUpService.getRule();
    this.isEnrolledInFee = RoundUpModule.getRoundUp?.isFeeEnabled ?? false;
    this.isPaused = RoundUpModule.getRoundUp?.paused ?? false;
    this.maximumRoundUp = RoundUpModule.getRoundUp?.maximumRoundUps ?? null;
    this.minimumRoundUp = RoundUpModule.getRoundUp?.minimumRoundUps ?? RoundUpModule.getEcosystemMinimum;
    this.roundUpRuleId = RoundUpModule.getRoundUp?.id ?? null;
  }

  async onClickChangeRoundUp(): Promise<void> {
    this.loading = true;
    if (
      this.minimumRoundUp &&
      this.maximumRoundUp &&
      parseFloat(this.minimumRoundUp.toString()) >= parseFloat(this.maximumRoundUp.toString())
    ) {
      this.snackbar.error =
        'Error Updating Rule! Monthly maximum amount must be greater than monthly minimum amount.';
      this.snackbar.show = true;
      this.loading = false;
      return;
    }
    await this.updatePaused();
    this.updateRoundUpRule();
    this.loading = false;
  }

  async updatePaused(): Promise<void> {
    if (this.changePausedRoundUp) {
      if (this.isPaused) {
        await RoundUpService.resumeRoundUpRule();
      } else if (this.currentBalanceDeal) {
        await RoundUpService.pauseRoundUpRule({
          status: this.currentBalanceDeal as RoundUpRuleStatusEnum
        });
      }
    }
  }

  async updateRoundUpRule(): Promise<void> {
    this.loading = true;
    try {
      this.minimumRoundUp =
        this.minimumRoundUp && this.minimumRoundUp !== this.ecosystemMinimum
          ? parseFloat(this.minimumRoundUp.toString())
          : parseFloat(this.ecosystemMinimum.toString());
      this.maximumRoundUp = this.maximumRoundUp ? parseFloat(this.maximumRoundUp.toString()) : null;
      const result = await RoundUpService.updateRoundUpRule({
        maximumRoundUps: this.maximumRoundUp ?? undefined,
        minimumRoundUps: this.minimumRoundUp,
        isFeeEnabled: this.isEnrolledInFee
      });
      if (this.changePausedRoundUp) {
        this.snackbar.success = `Round up was ${this.isPaused ? 'resumed' : 'paused'}!`;
      } else {
        this.snackbar.success = 'Round up rule updated!';
      }
      this.isPaused = result.paused;
      this.resetData();
    } catch (error) {
      this.snackbar.error = 'Error Updating Rule!';
    } finally {
      this.snackbar.show = true;
    }
    this.loading = false;
  }

  /******************* LIFECYCLE *******************/
  async created(): Promise<void> {
    this.loading = true;
    await this.fetchLinkedCards();
    await this.fetchRoundUpStats();
    await this.fetchRoundUpRuleForUser();
    await this.getRoundUpGlobalSettings();
    this.loading = false;
  }
}
