// Types
import { IPaymentMethodResponse } from './payment-method.types';

export interface ICharge {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  chargedTotal: number;
  currencyCode: string;
  rangeEndDate: Date;
  rangeStartDate: Date;
  paymentMethod: IPaymentMethodResponse;
  transactionNumber: number;
}

export interface ICreateRoundUpRule {
  minimumRoundUps?: number;
  maximumRoundUps?: number;
  startDate?: string; // format: 2022-11-20T00:00:00.000Z
  endDate?: string;
}

export interface IPauseRoundUp {
  status: RoundUpRuleStatus;
}

export interface IRoundUpRuleResponse {
  id: string;
  minimumRoundUps?: number;
  maximumRoundUps?: number;
  paused: boolean;
  startDate: Date;
  endDate: Date | null;
  isFeeEnabled?: boolean;
}

export interface IPauseRoundUp {
  status: RoundUpRuleStatus;
}

export interface IRoundUpRuleGlobalSettings {
  ecosystemFee?: number;
  ecosystemMinimum: number;
}

export interface IRoundUpRuleUpdate {
  isFeeEnabled?: boolean;
  maximumRoundUps?: number;
  minimumRoundUps?: number;
}

export enum RoundUpRuleStatus {
  /** Balance gets carried over to next month, card isn't charged in the start of next month */
  PAUSED_CARRY_OVER_BALANCE = 'paused_carry_over_balance',
  /** Balance gets cleared from user's account, card isn't charged in the start of next month */
  PAUSED_KEEP_BALANCE = 'paused_keep_balance',
  /** Charge card in the start of next month for current balance */
  PAUSED_PROCESS_BALANCE = 'paused_process_balance',
  UNPAUSED = 'unpaused'
}

export interface IRoundUpStats {
  carryOverAmount: number;
  roundUpAmount: number;
  roundUpTotalAmount: number;
  roundUpYearToDate: number;
}
